import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "helping-your-teenager-deal-with-problems"
    }}>{`Helping your teenager deal with problems`}</h1>
    <p>{`Problems are a part of life. They can be frustrating, but having a plan of action can make tackling these problems easier. But we’re not born with problem-solving skills; we need to learn and develop them. You have an important role to play in helping your teenager through this process of learning and growing.`}</p>
    <p>{`When your teenager is facing a difficult problem, it can be tempting to step in and fix things for them. But you won’t always be around to give your teenager advice or tips to help them overcome life’s hurdles. The best way for you to help your teenager is to teach them skills and strategies so they can solve problems on their own.`}</p>
    <h3 {...{
      "id": "strategies-you-can-teach-your-teenager-include"
    }}>{`Strategies you can teach your teenager include:`}</h3>
    <ul>
      <li parentName="ul">{`Effective problem-solving approaches.`}</li>
      <li parentName="ul">{`How to set realistic goals.`}</li>
      <li parentName="ul">{`Stress management skills for when things don’t go their way.`}</li>
      <li parentName="ul">{`Skills to cope with pressures and expectations of life.`}</li>
      <li parentName="ul">{`Ways to deal with problems at school or with friends.`}</li>
    </ul>
    <SingleImage smallGridSize={12} gridSize={10} src="/images/m8/38.svg" alt="friends hero image" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      